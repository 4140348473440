<template>
  <div>
    <!-- 头部 -->
    <Menu :actived="0" />
    <!-- 头部 -->

    <!-- 轮播图 -->
    <Carousel :bannerList="bannerList" :mode="bannerMode"></Carousel>
    <!-- 轮播图 -->

    <div class="template7_inner_container">
      <!-- 分类 -->
      <div class="template7_index_waterfall_wrap">
      
        <Title settitle="作品分类" themeType="3" tourl="/worksList"/>

        <div class="waterfall_cover_wrap" style="padding:0;">
          <div
            class="waterfall_cover_box_5 water_cover_css1"
            v-for="item in typeList"
            :key="item.id">
            <i style="display: block; padding-bottom: 66.69921875%"></i>
            <img
              class="img-fluid"
              :src="item.cover"
              alt="loading" />
            <router-link :to="`/worksList?class=`+item.id">
              <div class="hoverBg3"></div>
            </router-link>
            <div class="vcenter">
              <div>{{ item.name }}</div>
              <!-- <div>Japan</div> -->
            </div>
          </div>
        </div>

        

      </div>
      <!-- 分类 -->

      <!-- 案例 -->
      <Title settitle="作品欣赏" themeType="3" tourl="/worksList" />

      <div class="waterfall_cover_wrap" style="padding:0 0 40px 0;">
      
        <div
          class="waterfall_cover_box_5"
          style="
                    margin: 2px;
                    position: relative;
                    cursor: pointer;
                    display: inline-block;
                    width: 299.8535871156662px;
                    -webkit-flex-grow: 299.8535871156662;
                    flex-grow: 299.8535871156662;
                    opacity: 1;
                    overflow: hidden;
                  "
          v-if="worksList[0] !== undefined">
          <router-link target="_blank" class="router" :to="{path:'/worksInfo',query: {id: worksList[0].id}}">
            <i style="display: block; padding-bottom: 66.69921875%"></i><img
              style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      vertical-align: bottom;
                    "
              class="img-fluid"
              :src="worksList[0].cover"
              alt="loading" />
          </router-link>
        </div>
      
      
      
        <div
          class="waterfall_cover_box_5"
          style="
                    margin: 2px;
                    position: relative;
                    cursor: pointer;
                    display: inline-block;
                    width: 299.8535871156662px;
                    -webkit-flex-grow: 299.8535871156662;
                    flex-grow: 299.8535871156662;
                    opacity: 1;
                    overflow: hidden;
                  "
          v-if="worksList[1] !== undefined">
          <router-link target="_blank" class="router" :to="{path:'/worksInfo',query: {id: worksList[1].id}}">
            <i style="display: block; padding-bottom: 66.69921875%"></i><img
              style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      vertical-align: bottom;
                    "
              class="img-fluid"
              :src="worksList[1].cover"
              alt="loading" />
          </router-link>
        </div>
      
        <div
          class="waterfall_cover_box_5"
          style="
                    margin: 2px;
                    position: relative;
                    cursor: pointer;
                    display: inline-block;
                    width: 299.8535871156662px;
                    -webkit-flex-grow: 299.8535871156662;
                    flex-grow: 299.8535871156662;
                    opacity: 1;
                    overflow: hidden;
                  "
          v-if="worksList[2] !== undefined">
          <router-link target="_blank" class="router" :to="{path:'/worksInfo',query: {id: worksList[2].id}}">
            <i style="display: block; padding-bottom: 66.69921875%"></i>
            <img
              style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      vertical-align: bottom;
                    "
              class="img-fluid"
              :src="worksList[2].cover"
              alt="loading" />
          </router-link>
        </div>
      
        <div
          class="waterfall_cover_box_5"
          style="
                    margin: 2px;
                    position: relative;
                    cursor: pointer;
                    display: inline-block;
                    width: 299.8535871156662px;
                    -webkit-flex-grow: 299.8535871156662;
                    flex-grow: 299.8535871156662;
                    opacity: 1;
                    overflow: hidden;
                  "
          v-if="worksList[3] !== undefined">
          <router-link target="_blank" class="router" :to="{path:'/worksInfo',query: {id: worksList[3].id}}">
            <i style="display: block; padding-bottom: 66.69921875%"></i>
            <img
              style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      vertical-align: bottom;
                    "
              class="img-fluid"
              :src="worksList[3].cover"
              alt="loading" />
          </router-link>
        </div>
      
        <div
          class="waterfall_cover_box_5"
          style="
                    margin: 2px;
                    position: relative;
                    cursor: pointer;
                    display: inline-block;
                    width: 299.8535871156662px;
                    -webkit-flex-grow: 299.8535871156662;
                    flex-grow: 299.8535871156662;
                    opacity: 1;
                    overflow: hidden;
                  "
          v-if="worksList[4] !== undefined">
          <router-link target="_blank" class="router" :to="{path:'/worksInfo',query: {id: worksList[4].id}}">
            <i style="display: block; padding-bottom: 66.69921875%"></i>
            <img
              style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      vertical-align: bottom;
                    "
              class="img-fluid"
              :src="worksList[4].cover"
              alt="loading" />
          </router-link>
        </div>
      
        <div
          class="waterfall_cover_box_5"
          style="
                    margin: 2px;
                    position: relative;
                    cursor: pointer;
                    display: inline-block;
                    width: 300.2932551319648px;
                    -webkit-flex-grow: 300.2932551319648;
                    flex-grow: 300.2932551319648;
                    opacity: 1;
                    overflow: hidden;
                  "
          v-if="worksList[5] !== undefined">
          <router-link target="_blank" class="router" :to="{path:'/worksInfo',query: {id: worksList[5].id}}">
            <i style="display: block; padding-bottom: 66.69921875%"></i>
            <img
              style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      vertical-align: bottom;
                    "
              class="img-fluid"
              :src="worksList[5].cover"
              alt="loading" />
          </router-link>
        </div>
      
        <div
          class="waterfall_cover_box_5"
          style="
                    margin: 2px;
                    position: relative;
                    cursor: pointer;
                    display: inline-block;
                    width: 299.8535871156662px;
                    -webkit-flex-grow: 299.8535871156662;
                    flex-grow: 299.8535871156662;
                    opacity: 1;
                    overflow: hidden;
                  "
          v-if="worksList[6] !== undefined">
          <router-link target="_blank" class="router" :to="{path:'/worksInfo',query: {id: worksList[6].id}}">
            <i style="display: block; padding-bottom: 66.69921875%"></i>
            <img
              style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      vertical-align: bottom;
                    "
              class="img-fluid"
              :src="worksList[6].cover"
              alt="loading" />
          </router-link>
        </div>
      
        <div
          class="waterfall_cover_box_5"
          style="
                    margin: 2px;
                    position: relative;
                    cursor: pointer;
                    display: inline-block;
                    width: 199.92190550566184px;
                    -webkit-flex-grow: 199.92190550566184;
                    flex-grow: 199.92190550566184;
                    opacity: 1;
                    overflow: hidden;
                  "
          v-if="worksList[7] !== undefined">
          <router-link target="_blank" class="router" :to="{path:'/worksInfo',query: {id: worksList[7].id}}">
            <i
              style="
                    display: block;
                    padding-bottom: 100.03906250000001%;
                  "></i>
            <img
              style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      vertical-align: bottom;
                    "
              class="img-fluid"
              :src="worksList[7].cover"
              alt="loading" />
          </router-link>
        </div>
      
        <div
          class="waterfall_cover_box_5"
          style="
                    margin: 2px;
                    position: relative;
                    cursor: pointer;
                    display: inline-block;
                    width: 300.5135730007337px;
                    -webkit-flex-grow: 300.5135730007337;
                    flex-grow: 300.5135730007337;
                    opacity: 1;
                    overflow: hidden;
                  "
          v-if="worksList[8] !== undefined">
          <router-link target="_blank" class="router" :to="{path:'/worksInfo',query: {id: worksList[8].id}}">
            <i style="display: block; padding-bottom: 66.69921875%"></i>
            <img
              style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      vertical-align: bottom;
                    "
              class="img-fluid"
              :src="worksList[8].cover"
              alt="loading" />
          </router-link>
        </div>
      
           
      
      </div>
      
      <router-link class="more_btn2" to="/worksList">MORE</router-link>
      
      <!-- 案例 -->


      <!-- 服务套餐 -->
      <div class="template7_index_waterfall_wrap">
        
        <Title settitle="服务套餐" themeType="3" tourl="/goodsList"/>



        <div class="work_list_wrap fadeIn animation2">
          <div class="work_list_content" style="transform: translateX(0)">

            <div
              class="work_item_wrap"
              v-for="item in goodsList"
              :key="item.id"
            >
              <div class="work_item">
                <a>
                  <div style="width: 100%; overflow: hidden">
                    <div class="cover_url_item">
                      <router-link target="_blank" class="router" :to="{path:'/goodsInfo',query: {id: item.id}}">
                        <img :src="item.cover" class="img-fluid" alt=""/>
                      </router-link>
                    </div>
                  </div>
                  <div class="title ellipsis">
                    {{ item.name }}
                  </div>
                  <div class="sub_title">{{ item.price }}</div>
                </a>
                <p class="live_tag_wrap">
                  <i class="icon-video05"></i
                  ><span><span>热门套餐</span></span>
                </p>
              </div>

            </div>

            
          </div>
        </div>

        <div class="">
          <router-link class="more_btn" to="/goodsList">MORE</router-link>
        </div>
        
      </div>
      <!-- 服务套餐 -->

      <!-- 简介 -->
      <div class="template7_description_wrap">
        <router-link class="studioDesc" to="/about">
          <div class="description_wrap">
            <div
              class="avatar_url"
            >
              <img :src="logoUrl" class="img-fluid" alt="">
            </div>
            <div class="header_des">
              <div class="title">{{ studioName }}</div>
              <div class="sub_title">About Us</div>
              <div class="description_content">
                <p>
                  {{ studioDesc }}
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <!-- 简介 -->

      <!-- 视频 -->
      <div class="template7_index_waterfall_wrap template8_video_index_container" v-if="video">
        <!-- <div class="des_header">
          <p class="title"><span>VIDEO</span></p>
          <p class="sub_title">视频展示</p>
        </div> -->
        <Title settitle="视频展示" themeType="3" />

        <div class="video_wrap" style="overflow:hidden;background:#000">
          <div class="video_is_pfop" style="height:600px">
            <video
              :src="video"
              style="width:100%;height:600px;"
              controls
              controlsList="nodownload"
              autoplay
              loop></video>
          </div>
        </div>
      </div>
      <!-- 视频 -->

      

      <!-- 底部 -->
      <Footer
        :studioName="this.studioName"
        :weixin="this.weixin"
        :studioAddress="this.studioAddress"
        :banner="footBg"
        :wxQrcode="wxcode"
      />
      <!-- 底部 -->

    </div>
  </div>
</template>
<script>
import axios from 'axios'
import api from '@/utils/api.js'
import Util from '@/utils/util.js'
import '../../public/styles/template7.css'
import '../../public/styles/home.css'
import Title from './common/Title.vue'
import Footer from '../components/common/Footer'
import Menu from '../components/common/Menu'
import Carousel from '../components/common/Carousel'
export default {
  name:'Home',
  data() {
    return {
      logoUrl:'',
      bannerList:[],
      studioName:'',
      studioDesc:'',
      studioAddress:'',
      goodsList:[],
      video:'',
      weixin:'',
      worksList:[],
      codeShow:0,
      typeList:[],
      footBg:'',
      wxcode:'',
      bannerMode:0
    }
  },
  components:{
    Title,
    Menu,
    Footer,
    Carousel
  },
  mounted: function () {
    this.studioInfo = this.$store.state.info
		this.init()
  },
  methods: {
    init(){
			this.imgSuffix = Util.getImageThumbSuffix(this.studioInfo.storageType)
			this.getBannerList()
			this.studioName = this.studioInfo.brand_name
      this.studioDesc = this.studioInfo.brand_desc
			this.studioAddress = this.studioInfo.address
			this.weixin = this.studioInfo.kefu_weixin
			this.phone = this.studioInfo.kefu_phone
			this.bannerMode = Number(this.studioInfo.banner_type)
      try {
        this.logoUrl = this.studioInfo.logo_url[0][0]
      } catch (error) {
        console.log(error);
      }
      try {
        this.video = this.studioInfo.applet_video[0][0]
      } catch (error) {
        console.log(error);
      }
      try {
        this.wxcode = this.studioInfo.corp_qrcode_url[0][0]
      } catch (error) {
        console.log(error);
      }
      try {
        this.footBg = this.studioInfo.banner_img[0][0]
      } catch (error) {
        console.log(error);
      }
      this.getTypeList()
			this.getWorksList()
			this.getGoodsList()
		},
		getBannerList(){
			this.bannerList = []
			this.studioInfo.banner_img.forEach(item=>{
				this.bannerList.push(item[0] + "/applet_size")
			})
		},
		getWorksList(){
      const that = this
			const param = {
					appId:this.studioInfo.applet_appid,
					type:3,
					pageNo:1,
					pageSize:12
				}
			axios.get(api.GET_WORKS_LIST,{
				params:param
			}).then(
				function(response) {
					that.worksList = []
					response.data.data.forEach(item=>{
						if(item['works_cover'] != null && item['works_cover'] != ''){
							that.worksList.push({
								id:item.id,
								cover:item['works_cover'][0][0] + that.imgSuffix
							})
						}
					})
				},
				function(res) {
					console.log(res.data)
				}
			)
		},
		getGoodsList(){
      const that = this
			const param = {
					appId:this.studioInfo.applet_appid,
					type:1,
					pageNo:1,
					pageSize:10
				}
			axios.get(api.GET_GOODS_LIST,{
				params:param
			}).then(
				function(response) {
					that.goodsList = []
					response.data.data.forEach(item=>{
						if(item['goods_cover'] != null && item['goods_cover'] != ''){
							that.goodsList.push({
								id:item.id,
								cover:item['goods_cover'][0][0] + that.imgSuffix,
                name:item.goods_name,
                price:item.sell_price
							})
						}
					})
				},
				function(res) {
					console.log(res.data)
				}
			)
		},
    getTypeList(){
      const that = this
			const param = {
					appId:this.studioInfo.applet_appid,
					type:3,
					pageNo:1,
					pageSize:5
				}
			axios.get(api.GET_CLASS_LIST,{
				params:param
			}).then(
				function(response) {
					const fields = {}
          response.data.data.fields.forEach(item => {
            if (item.system == 1) {
              fields[item.systemFields] = item.dataIndex
            }
          })
          if (response.data.data.data.length != 0) {
          
            response.data.data.data.forEach((item) => {
              if(item[fields['class_img']] != null && item[fields['class_img']] != ''){
                const classItem = {
                  id: item.id,
                  name: item[fields['class_name']],
                  cover: item[fields['class_img']][0][0]
                }
                that.typeList.push(classItem)
              }
            })
          }
				},
				function(res) {
					console.log(res.data)
				}
			)
    }


  }
}
</script>
<style>
#carousel-fade{
  width: 100%;
  height: 46.875vw;
  position: fixed;
  overflow: hidden;
  margin-top: 70px;
}
.nav-a{
  text-decoration: none;
}
.studioDesc{
  text-decoration: none;
}
.more_btn{
  text-decoration: none;
}
.more_btn2{
    width: 218px;
    height: 44px;
    line-height: 44px;
    font-size: 15px;
    color: #bdbdbd;
    margin: 0 auto 60px;
    text-align: center;
    border: 1px solid #dfdfdf;
    display: block;
    transition: all 0.3s;
    text-decoration: none;
}
.more_btn2:hover{
    color: #fff;
    background:rgba(130, 200, 174, .8) !important;
}
a{
  text-decoration: none !important;
}
.water_cover_css1{
  margin:5px;
  position: relative;
  background-color: #f0f0f0;
  cursor: pointer;
  display: inline-block;
  -webkit-flex-grow: 299.8535871156662;
  opacity: 1;
  height: 350px;
  width:40%!important;
  overflow: hidden;
}
.water_cover_css1 img{
  position: absolute;
  top: 0;
  width: 100%;
  vertical-align: bottom;
}
</style>
